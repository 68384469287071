import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyBgPcNc2AoL7NKZO3RRtI2JEw1pmBraDGA',
  authDomain: 'project-management-8f1d0.firebaseapp.com',
  projectId: 'project-management-8f1d0',
  storageBucket: 'project-management-8f1d0.appspot.com',
  messagingSenderId: '378902600139',
  appId: '1:378902600139:web:7c3a0e5af77a1cdc3fbe4e',
};

firebase.initializeApp(firebaseConfig);

const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();
const projectStorage = firebase.storage();
const timestamp = firebase.firestore.Timestamp;

export { projectFirestore, projectAuth, projectStorage, timestamp };
