import { Link } from 'react-router-dom';
import { useLogout } from '../hooks/useLogout';
import { useAuthContext } from '../hooks/useAuthContext';

//Styles
import './Navbar.css';
import Logo from '../assets/logo.png';

export default function Navbar() {
  const { logout, isPending } = useLogout();
  const { user } = useAuthContext();

  return (
    <div className='navbar'>
      <ul>
        <li className='logo'>
          <img src={Logo} alt='logo' />
          <span>Project Management</span>
        </li>
        {!user && (
          <>
            <li>
              <Link to='login'>Login</Link>
            </li>
            <li>
              <Link to='signup'>Signup</Link>
            </li>
          </>
        )}

        {user && (
          <li>
            {!isPending && (
              <button className='btn' onClick={logout}>
                Logout
              </button>
            )}
            {isPending && (
              <button className='btn' disabled>
                Loggin Out...
              </button>
            )}
          </li>
        )}
      </ul>
    </div>
  );
}
